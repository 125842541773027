<template>
   <el-table
        :data="tableData"
        class="report-shop-table"
        height="70vh"
        style="width: 100%;background#f8f8f8;"
      >
        <el-table-column
          prop="name"
          width="230"
          label="商品名称"
        >
        </el-table-column>

        <el-table-column
          sortable
          prop="sum"
          width="120"
          label="销售量"
        >
        </el-table-column>

        <el-table-column
          sortable
          prop="zb_sum"
          width="120"
          label="销售量占比"
        >
          <template slot-scope="scope">
            {{scope.row.zb_sum}}%
          </template>
        </el-table-column>
        <el-table-column
          sortable
          prop="tb_sum"
          label="销售量同比"
          width="120"
        >
          <template slot-scope="scope">
            <span :style="scope.row.tb_sum_color">{{scope.row.tb_sum_fh}}
            {{scope.row.tb_sum}}%
            </span>
          </template>
        </el-table-column>

        <el-table-column
          sortable
          prop="hb_sum"
          label="销售量环比"
          width="120"
        >
          <template slot-scope="scope">
            <span :style="scope.row.hb_sum_color">{{scope.row.hb_sum_fh}}
            {{scope.row.hb_sum}}%</span>
          </template>
        </el-table-column>

        <!-- 金额 -->
        <el-table-column
          sortable
          prop="price"
          width="120"
          label="销售额"
        >
        </el-table-column>

        <el-table-column
          sortable
          prop="zb_price"
          width="120"
          label="销售额占比"
        >
          <template slot-scope="scope">
            {{scope.row.zb_price}}%
          </template>
        </el-table-column>
        <el-table-column
          sortable
          prop="tb_price"
          label="销售额同比"
          width="120"
        >
          <template slot-scope="scope">
            <span :style="scope.row.tb_price_color">{{scope.row.tb_price_fh}}
            {{scope.row.tb_price}}%
            </span>
          </template>
        </el-table-column>

        <el-table-column
          sortable
          prop="hb_price"
          label="销售额环比"
          width="120"
        >
          <template slot-scope="scope">
            <span :style="scope.row.hb_price_color">{{scope.row.hb_price_fh}}
            {{scope.row.hb_price}}%</span>
          </template>
        </el-table-column>

      </el-table>
</template>

<script>

export default {
  props: {
    tableData: {}
  },
  data () {
    return {
    }
  }
}

</script>
<style lang='' scoped>

</style>
