<template>
  <el-table
    v-if="list.length>0 && top.length > 0"
    :data="list"
    height="70vh"
    border
    style="width: 100%"
  >
    <el-table-column
      :prop="item"
      :label="item"
      width="180"
      v-for="(item,index) in top"
      :key="index"
    >
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    top: {},
    list: {}
  }
}
</script>
<style lang='' scoped>
</style>
