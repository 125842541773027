<template>
  <div class="">
    <h4>{{typeName}}数据详情</h4>
    <!-- 按钮tab组 -->
    <el-tabs
      @tab-click="tabClick"
      v-model="activeName"
      type="border-card">
      <el-tab-pane
      :name="item.name"
      :label="item.label"
      v-for="(item,index) in tabs" :key="index">
        <!-- 商品 -->
        <shop
        v-if="activeName === 'shop'"
        :tableData.sync="reportData.shop.sumShop"/>
        <baseView
        v-else
        :top.sync="top"
        :list.sync="list"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import shop from './pc-view/shop'
import baseView from './pc-view/base'
export default {
  name: 'DataView',
  components: {
    baseView, shop
  },
  data () {
    return {
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }
      ],
      activeName: '', // 选中的tab昵称
      tabs: [
        {
          name: 'xs',
          label: '销售额均值走势'
        },
        {
          name: 'xs-two',
          label: '销售额走势'
        },
        {
          name: 'store',
          label: '各分店销售额'
        },
        {
          name: 'class',
          label: '品类销售排行'
        },
        {
          name: 'shop',
          label: '商品销售排行'
        },
        {
          name: 'member-from',
          label: '会员来源'
        },
        {
          name: 'coupon',
          label: '优惠券使用'
        }
      ],
      typeName: '',
      selectData: null,
      reportData: null,
      list: [],
      top: []
    }
  },

  methods: {
    tabClick (e) {
      console.log(e)
      console.log(this.activeName)
    },
    initData () {
      this.selectData = null
      this.reportData = null
      this.activeName = ''
      const selectData = window.localStorage.getItem('selectType')
      this.selectData = JSON.parse(selectData)
      const reportData = window.localStorage.getItem('reportData')
      this.reportData = JSON.parse(reportData)
      // const query = this.$router.currentRoute.query.name
      // if (query) {
      //   this.activeName = query
      // }
      switch (this.selectData.type) {
        case 1:
          this.typeName = '今日'
          break
        case 2:
          this.typeName = '本周'
          break
        case 3:
          this.typeName = '本月'
          break
        case 4:
          this.typeName = this.selectData.selectTime[0] + '至' + this.selectData.selectTime[1]
          break
        default:
          break
      }
    }
  },
  watch: {
    activeName (val) {
      switch (val) {
        // 销售额均值走势
        case 'xs':
          this.top = this.reportData.divine.bar.columns || []
          this.list = this.reportData.divine.bar.rows || []
          break
          // 销售趋势走势
        case 'xs-two':
          this.top = this.reportData.divine.barTwo.columns || []
          this.list = this.reportData.divine.barTwo.rows || []
          break
        case 'store':
          this.top = this.reportData.divine.zhu.columns || []
          this.list = this.reportData.divine.zhu.rows || []
          break
          // 品类
        case 'class':
          this.top = this.reportData.shop.zhu.columns || []
          this.list = this.reportData.shop.zhu.rows || []
          break
          // 会员来源
        case 'member-from':
          this.top = this.reportData.users.form.columns || []
          this.list = this.reportData.users.form.rows || []
          break
          // 优惠券
        case 'coupon':
          this.top = this.reportData.other.bar.columns || []
          this.list = this.reportData.other.bar.rows || []
          break
        default:
          break
      }
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
